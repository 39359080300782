<div class="risk-report-form-container">
  <div class="risk-report-form-header">
    <h1 class="risk-report-form-header__title" *ngIf="!riskId">Create risk</h1>
    <h1 class="risk-report-form-header__title" *ngIf="riskId">Edit risk</h1>
  </div>

  <nav class="risk-nav-main">
    <ul class="risk-nav-list">
      <li>
        <a class="risk-nav-link" routerLink="/home">Programs</a>
      </li>
      <li>
        <a routerLink="''" class="risk-nav-link">></a>
      </li>
      <li>
        <a
          routerLink="/home/{{ sciencePrograms?.id }}/{{
            sciencePrograms?.official_code
          }}"
          class="risk-nav-link"
          >&nbsp;Risk report<span class="line">&nbsp;/</span>&nbsp;{{
            officalCode
          }}</a
        >
      </li>
      <li>
        <a routerLink="''" class="risk-nav-link">></a>
      </li>
      <li>
        <a
          routerLink="/home/{{ sciencePrograms?.id }}/{{
            sciencePrograms?.official_code
          }}/create-risk"
          class="risk-nav-link"
          *ngIf="!riskId"
          >Create risk</a
        >
        <a
          routerLink="/home/{{ sciencePrograms?.id }}/{{
            sciencePrograms?.official_code
          }}/edit-risk/{{ riskId }}"
          class="risk-nav-link"
          *ngIf="riskId"
          >&nbsp;Edit risk<span class="line">&nbsp;/</span>&nbsp;{{ riskId }}</a
        >
      </li>
    </ul>
  </nav>

  <div class="risk-report-form-content-container" *ngIf="newRiskForm">
    <div class="grid-2">
      <div class="risk-report-form-note-box">
        <svg
          class="risk-report-form-note-content-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
            fill="#2196F3"
          />
          <path
            d="M9.95227 9.95312H12.0475V15.715H9.95227V9.95312Z"
            fill="white"
          />
          <path
            d="M11.0002 8.38467C11.7234 8.38467 12.3097 7.79838 12.3097 7.07515C12.3097 6.35192 11.7234 5.76562 11.0002 5.76562C10.277 5.76562 9.69067 6.35192 9.69067 7.07515C9.69067 7.79838 10.277 8.38467 11.0002 8.38467Z"
            fill="white"
          />
        </svg>
        <div class="risk-report-form-note-content-container">
          <p class="risk-report-form-note-content-text">
            Instructions before you can save:​
          </p>
          <ol class="risk-report-form-note-content-list">
            <li>Review Risk Category and change if not correct (mandatory)</li>
            <li>
              On first use, adding the Risk Owner is mandatory. The authorized
              user can change the risk owner later.
            </li>
            <li>​Update Risk Description (mandatory)</li>
            <li>​Update Risk Targets</li>
            <li>​Add additional Actions to Manage Risk (optional)</li>
            <!-- <li>Review Status of Actions for each risk (optional)​</li> -->
          </ol>
          <!-- <p class="risk-report-form-note-content-note">
        Note: these steps are required even if the Risk is tagged as Complete.
        ​
      </p> -->
        </div>
      </div>
    </div>
    <form [formGroup]="newRiskForm" class="risk-report-form">
      <!-- //Created by filed----- -->

      <div class="risk-report-form-field field-1">
        <mat-label class="risk-report-form-field__label">Created by</mat-label>
        <!-- <mat-form-field
          class="form-dialog-content__field n"
          floatLabel="always"
          hideRequiredMarker
          appearance="outline" 
        >-->
        <div class="risk-report-form-field-icon-box">
          <input
            [disabled]="true"
            [value]="
              checkIfRiskExist[0]?.created_by?.full_name || user_info?.full_name
            "
            class="risk-report-form-field-box__input report-form-field-size"
            matInput
            placeholder="Created by"
          />

          <mat-icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g
                [matTooltipClass]="{ 'tool-tip': true }"
                [matTooltipPosition]="'right'"
                matTooltip="The user who is entering the risk is automatically identified by the tool via user login"
                clip-path="url(#clip0_14774_17883)"
              >
                <circle cx="10" cy="10" r="9" fill="white" />
                <path
                  d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
                  fill="#808080"
                />
              </g>
              <defs>
                <clipPath id="clip0_14774_17883">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </mat-icon>
        </div>
      </div>
      <!-- </mat-form-field> -->

      <!-- //Risk title filed----- -->

      <div class="risk-report-form-field field-1">
        <mat-label class="risk-report-form-field__label"
          >Risk title<span class="risk-report-form-field__label__star"
            >*</span
          ></mat-label
        >
        <!-- <mat-form-field
          class="form-dialog-content__field n"
          floatLabel="always"
          hideRequiredMarker
          appearance="outline" 
        >-->
        <div class="risk-report-form-field-icon-box">
          <textarea
            class="risk-report-form-field-box__input cursor-input report-form-field-size"
            matInput
            placeholder="Risk title"
            formControlName="title"
            rows="4"
            style="height: 80px; resize: none"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="10"
          >
          </textarea>
          <mat-icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g
                [matTooltipPosition]="'right'"
                matTooltip="To make the risk visible in a register, the risk title should summarize the key points in 50 words or less."
                clip-path="url(#clip0_14774_17883)"
              >
                <circle cx="10" cy="10" r="9" fill="white" />
                <path
                  d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
                  fill="#808080"
                />
              </g>
              <defs>
                <clipPath id="clip0_14774_17883">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </mat-icon>
        </div>
        <mat-error
          class="error"
          *ngIf="newRiskForm.controls['title'].errors?.['required'] && (newRiskForm.controls['title'].dirty || newRiskForm.controls['title'].touched)"
          >Risk Title is required</mat-error
        >
        <mat-error
          class="error"
          *ngIf="newRiskForm.controls?.title?.errors?.maxword"
          >Max words you can use is
          {{ newRiskForm.controls?.title?.errors?.maxword?.max }} you used
          {{ newRiskForm.controls?.title?.errors?.maxword.actual }}</mat-error
        >
        <!-- </mat-form-field> -->
      </div>

      <!-- //Detailed description filed----- -->

      <div class="risk-report-form-field field-1">
        <mat-label class="risk-report-form-field__label"
          >Detailed description<span class="risk-report-form-field__label__star"
            >*</span
          ></mat-label
        >
        <!-- <mat-form-field
          class="form-dialog-content__field n"
          floatLabel="always"
          hideRequiredMarker
          appearance="outline" 
        >-->
        <div class="risk-report-form-field-icon-box">
          <textarea
            class="risk-report-form-field-box__input cursor-input__description report-form-field-size__description"
            matInput
            rows="4"
            placeholder="Detailed Description"
            formControlName="description"
            style="height: 80px; resize: none"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="10"
          >
          </textarea>
          <!-- <mat-icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_14774_17883)">
                <circle cx="10" cy="10" r="9" fill="white" />
                <path
                  d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
                  fill="#808080"
                />
              </g>
              <defs>
                <clipPath id="clip0_14774_17883">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </mat-icon> -->
        </div>
        <mat-error
          class="error"
          *ngIf="newRiskForm.controls['description'].errors?.['required'] && (newRiskForm.controls['description'].touched || newRiskForm.controls['description'].dirty)"
          >Detailed Description is required</mat-error
        >
        <mat-error
          class="error"
          *ngIf="newRiskForm.controls?.description?.errors?.maxword"
          >Max words you can use is
          {{ newRiskForm.controls?.description?.errors?.maxword?.max }} you used
          {{
            newRiskForm.controls?.description?.errors?.maxword.actual
          }}</mat-error
        >
        <!-- </mat-form-field> -->
      </div>

      <!-- //Risk category filed----- -->

      <div class="risk-report-form-field field-1 m-b">
        <mat-label class="risk-report-form-field__label"
          >Risk category<span class="risk-report-form-field__label__star"
            >*</span
          ></mat-label
        >
        <!-- <mat-form-field
          class="form-dialog-content__field n"
          floatLabel="always"
          hideRequiredMarker
          appearance="outline" 
        >-->
        <div class="risk-report-form-field-icon-box">
          <mat-select
            formControlName="category_id"
            placeholder="Select risk category"
            class="risk-report-form-field-select"
          >
            <ng-container *ngFor="let riskCat of riskCategories">
              <mat-option *ngIf="riskCat.disabled == 0" [value]="riskCat.id">
                {{ riskCat.title }}
                <mat-icon
                  [matTooltipClass]="{ 'tool-tip': true }"
                  matTooltip="{{ riskCat.description }}"
                  [matTooltipPosition]="'above'"
                  matSuffix
                  >help_outline</mat-icon
                >
              </mat-option>
            </ng-container>
          </mat-select>

          <mat-icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g
                [matTooltipPosition]="'right'"
                matTooltip="The definition for each category is provided in the drop down list"
                clip-path="url(#clip0_14774_17883)"
              >
                <circle cx="10" cy="10" r="9" fill="white" />
                <path
                  d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
                  fill="#808080"
                />
              </g>
              <defs>
                <clipPath id="clip0_14774_17883">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </mat-icon>
        </div>

        <!-- </mat-form-field> -->

        <mat-error
          class="error"
          *ngIf="newRiskForm.controls['category_id'].errors?.['required'] && (newRiskForm.controls['category_id'].touched || newRiskForm.controls['category_id'].dirty)"
          >Risk category is required</mat-error
        >
        <mat-error
          class="error"
          *ngIf="newRiskForm.controls?.category_id?.errors?.maxword"
          >Max words you can use is
          {{ newRiskForm.controls?.category_id?.errors?.maxword?.max }} you used
          {{
            newRiskForm.controls?.category_id?.errors?.maxword.actual
          }}</mat-error
        >
      </div>

      <!-- //Risk owner filed----- -->

      <div class="risk-report-form-field field-1 m-b--2">
        <mat-label class="risk-report-form-field__label"
          >Risk owner<span class="risk-report-form-field__label__star"
            >*</span
          ></mat-label
        >
        <!-- <mat-form-field
          class="form-dialog-content__field n"
          floatLabel="always"
          hideRequiredMarker
          appearance="outline" 
        >-->
        <div class="risk-report-form-field-icon-box">
          <mat-select
            formControlName="risk_owner_id"
            placeholder="Risk Owner"
            class="risk-report-form-field-select"
          >
            <mat-option
              *ngFor="let riskrole of riskUsers"
              [value]="riskrole.id"
            >
              {{ riskrole?.user?.full_name || riskrole.email }}
            </mat-option>
          </mat-select>

          <mat-icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g
                [matTooltipPosition]="'right'"
                matTooltip="individuals who have the accountability and authority to manage risk."
                clip-path="url(#clip0_14774_17883)"
              >
                <circle cx="10" cy="10" r="9" fill="white" />
                <path
                  d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
                  fill="#808080"
                />
              </g>
              <defs>
                <clipPath id="clip0_14774_17883">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </mat-icon>
        </div>
        <mat-error
          class="error"
          *ngIf="newRiskForm.controls?.risk_owner_id.errors?.['required'] && (newRiskForm.controls?.risk_owner_id.dirty || newRiskForm.controls?.risk_owner_id.touched)"
          >Risk Owner is required</mat-error
        >
        <!-- </mat-form-field> -->
      </div>

      <!-- //grid-box----- -->

      <div class="risk-report-radio-buttons-grid-box">
        <!-- //grid-item-1----- -->
        <div class="el-1-line">
          <label class="risk-report-form-field__label"
            >Current Likelihood<span class="risk-report-form-field__label__star"
              >*</span
            ></label
          >
          <div class="risk-report-radio-buttons-flex-box">
            <div class="risk-report-radio-buttons-flex">
              <mat-radio-group
                formControlName="current_likelihood"
                aria-label="Select an option"
                (change)="checkedReqAssistance()"
                (change)="haveSameValue()"
              >
                <mat-radio-button value="1">1</mat-radio-button>
                <mat-radio-button value="2">2</mat-radio-button>
                <mat-radio-button value="3">3</mat-radio-button>
                <mat-radio-button value="4">4</mat-radio-button>
                <mat-radio-button value="5">5</mat-radio-button>
              </mat-radio-group>
              <mat-error
                class="error errorMessage"
                *ngIf="newRiskForm.controls['current_likelihood'].errors?.['required'] && (newRiskForm.controls['current_likelihood'].dirty || newRiskForm.controls['current_likelihood'].touched)"
                >Current Likelihood is required</mat-error
              >
            </div>
            <mat-icon
              matTooltip="Click to more info"
              [satPopoverAnchor]="popoverCurrentlikelihood"
              (click)="popoverCurrentlikelihood.toggle()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_14774_17883)">
                  <circle cx="10" cy="10" r="9" fill="white" />
                  <path
                    d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
                    fill="#808080"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_14774_17883">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </mat-icon>
            <sat-popover #popoverCurrentlikelihood hasBackdrop>
              <div class="popover">
                <div class="popover__head">
                  <h3 class="popover__h3">Current likelihood</h3>
                  <mat-icon
                    class="popover__icon"
                    (click)="popoverCurrentlikelihood.close()"
                    >close</mat-icon
                  >
                </div>
                <p class="popover__text">
                  the likelihood of the risk materializing within a given time
                  horizon that could result into the impact level assigned,
                  taking into account any existing controls and actions in place
                  and working.
                </p>
                <a
                  [routerLink]="['/glossary']"
                  [queryParams]="{ search: 'Current risk level', char: 'c' }"
                  target="_blank"
                  class="btn btn--outline"
                >
                  Learn more &rarr;
                </a>
              </div>
            </sat-popover>
          </div>
        </div>

        <!-- //grid-item-2----- -->
        <div class="el-1-line__2">
          <label class="risk-report-form-field__label"
            >Current Impact<span class="risk-report-form-field__label__star"
              >*</span
            ></label
          >
          <div class="risk-report-radio-buttons-flex-box">
            <div class="risk-report-radio-buttons-flex">
              <mat-radio-group
                formControlName="current_impact"
                aria-label="Select an option"
                (change)="checkedReqAssistance()"
                (change)="haveSameValue()"
              >
                <mat-radio-button value="1">1</mat-radio-button>
                <mat-radio-button value="2">2</mat-radio-button>
                <mat-radio-button value="3">3</mat-radio-button>
                <mat-radio-button value="4">4</mat-radio-button>
                <mat-radio-button value="5">5</mat-radio-button>
              </mat-radio-group>
              <mat-error
                class="error errorMessage"
                *ngIf="newRiskForm.controls['current_impact'].errors?.['required'] && (newRiskForm.controls['current_impact'].dirty || newRiskForm.controls['current_impact'].touched)"
                >Current Impact is required</mat-error
              >
            </div>
            <mat-icon
              matTooltip="Click to more info"
              [satPopoverAnchor]="popoverCurrentImpact"
              (click)="popoverCurrentImpact.toggle()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_14774_17883)">
                  <circle cx="10" cy="10" r="9" fill="white" />
                  <path
                    d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
                    fill="#808080"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_14774_17883">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </mat-icon>
            <sat-popover #popoverCurrentImpact hasBackdrop>
              <div class="popover">
                <div class="popover__head">
                  <h3 class="popover__h3">Current Impact</h3>

                  <mat-icon
                    class="popover__icon"
                    (click)="popoverCurrentImpact.close()"
                    >close</mat-icon
                  >
                </div>
                <p class="popover__text">
                  the expected impact on objectives if the risk materialises,
                  taking into account any existing controls and actions in place
                  and working.
                </p>
                <a
                  [routerLink]="['/glossary']"
                  [queryParams]="{ search: 'Current risk level', char: 'c' }"
                  target="_blank"
                  class="btn btn--outline"
                >
                  Learn more &rarr;
                </a>
              </div>
            </sat-popover>
          </div>
        </div>

        <!-- //grid-item-3----- -->
        <div class="risk-report-form-field-icon-box--2 el-1-line__3">
          <div class="risk-report-grid-item-text-box">
            <p class="risk-report-grid-item-text">
              Current Risk level:
              <span class="risk-report-grid-item-text__sub">
                {{
                  newRiskForm.value.current_likelihood *
                    newRiskForm.value.current_impact
                }}
              </span>
            </p>
          </div>

          <mat-icon
            matTooltip="Click to more info"
            (click)="popoverCurrentRiskLevel.toggle()"
            [satPopoverAnchor]="popoverCurrentRiskLevel"
            class="move-end"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_14774_17883)">
                <circle cx="10" cy="10" r="9" fill="white" />
                <path
                  d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
                  fill="#808080"
                />
              </g>
              <defs>
                <clipPath id="clip0_14774_17883">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </mat-icon>
          <sat-popover #popoverCurrentRiskLevel hasBackdrop>
            <div class="popover-2">
              <div class="popover-2__head">
                <h3 class="popover-2__h3">Current Risk level</h3>

                <mat-icon
                  class="popover-2__icon"
                  (click)="popoverCurrentRiskLevel.close()"
                  >close</mat-icon
                >
              </div>
              <p class="popover-2__text">
                Current level of risk considering existing controls in place.
              </p>
              <a
                [routerLink]="['/glossary']"
                [queryParams]="{ search: 'Current risk level', char: 'c' }"
                target="_blank"
                class="btn btn--outline"
              >
                Learn more &rarr;
              </a>
            </div>
          </sat-popover>
        </div>

        <!-- //grid-item-4----- -->
        <div>
          <label class="risk-report-form-field__label"
            >Target Likelihood<span class="risk-report-form-field__label__star"
              >*</span
            ></label
          >
          <div class="risk-report-radio-buttons-flex-box">
            <div class="risk-report-radio-buttons-flex">
              <mat-radio-group
                formControlName="target_likelihood"
                aria-label="Select an option"
                (change)="disapledCheckBox()"
                (change)="haveSameValue()"
              >
                <mat-radio-button value="1">1</mat-radio-button>
                <mat-radio-button value="2">2</mat-radio-button>
                <mat-radio-button value="3">3</mat-radio-button>
                <mat-radio-button value="4">4</mat-radio-button>
                <mat-radio-button value="5">5</mat-radio-button>
              </mat-radio-group>
              <mat-error
                class="error errorMessage"
                *ngIf="newRiskForm.controls['target_likelihood'].errors?.['required']  && (newRiskForm.controls['target_likelihood'].dirty || newRiskForm.controls['target_likelihood'].touched)"
                >Target Likelihood is required</mat-error
              >
            </div>
            <mat-icon
              matTooltip="Click to more info"
              [satPopoverAnchor]="popoverTargetLikelihood"
              (click)="popoverTargetLikelihood.toggle()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_14774_17883)">
                  <circle cx="10" cy="10" r="9" fill="white" />
                  <path
                    d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
                    fill="#808080"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_14774_17883">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </mat-icon>
            <sat-popover #popoverTargetLikelihood hasBackdrop>
              <div class="popover">
                <div class="popover__head">
                  <h3 class="popover__h3">Target Likelihood</h3>
                  <mat-icon
                    class="popover__icon"
                    (click)="popoverTargetLikelihood.close()"
                    >close</mat-icon
                  >
                </div>
                <p class="popover__text">
                  the targeted likelihood of the risk occurring within a given
                  time horizon that could result into the targeted impact level
                  assigned. Target likelihood could be achieved by putting in
                  place additional controls and actions to manage the risk
                  likelihood.
                </p>
                <a
                  [routerLink]="['/glossary']"
                  [queryParams]="{ search: 'Target risk level', char: 't' }"
                  target="_blank"
                  class="btn btn--outline"
                >
                  Learn more &rarr;
                </a>
              </div>
            </sat-popover>
          </div>
        </div>

        <!-- //grid-item-5----- -->

        <div>
          <label class="risk-report-form-field__label"
            >Target Impact<span class="risk-report-form-field__label__star"
              >*</span
            ></label
          >
          <div class="risk-report-radio-buttons-flex-box">
            <div class="risk-report-radio-buttons-flex">
              <mat-radio-group
                formControlName="target_impact"
                aria-label="Select an option"
                (change)="disapledCheckBox()"
                (change)="haveSameValue()"
              >
                <mat-radio-button value="1">1</mat-radio-button>
                <mat-radio-button value="2">2</mat-radio-button>
                <mat-radio-button value="3">3</mat-radio-button>
                <mat-radio-button value="4">4</mat-radio-button>
                <mat-radio-button value="5">5</mat-radio-button>
              </mat-radio-group>
              <mat-error
                class="error errorMessage"
                *ngIf="newRiskForm.controls['target_impact'].errors?.['required']  && (newRiskForm.controls['target_impact'].dirty || newRiskForm.controls['target_impact'].touched)"
                >Target Impact is required</mat-error
              >
            </div>

            <mat-icon
              matTooltip="Click to more info"
              [satPopoverAnchor]="popoverTargetImpact"
              (click)="popoverTargetImpact.toggle()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_14774_17883)">
                  <circle cx="10" cy="10" r="9" fill="white" />
                  <path
                    d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
                    fill="#808080"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_14774_17883">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </mat-icon>
            <sat-popover #popoverTargetImpact hasBackdrop>
              <div class="popover">
                <div class="popover__head">
                  <h3 class="popover__h3">Target Impact</h3>
                  <mat-icon
                    class="popover__icon"
                    (click)="popoverTargetImpact.close()"
                    >close</mat-icon
                  >
                </div>
                <p class="popover__text">
                  the targeted expected impact on objectives if the risk
                  materialises. Target impact could be achieved by putting in
                  place additional controls and actions to manage risk impact.
                </p>
                <a
                  [routerLink]="['/glossary']"
                  [queryParams]="{ search: 'Target risk level', char: 't' }"
                  target="_blank"
                  class="btn btn--outline"
                >
                  Learn more &rarr;
                </a>
              </div>
            </sat-popover>
          </div>
        </div>

        <!-- //grid-item-6----- -->
        <div class="risk-report-form-field-icon-box--3">
          <div class="risk-report-grid-item-text-box">
            <p class="risk-report-grid-item-text">
              Target Risk level:
              <span class="risk-report-grid-item-text__sub">
                {{
                  newRiskForm.value.target_impact *
                    newRiskForm.value.target_likelihood
                }}
              </span>
            </p>
          </div>

          <mat-icon
            matTooltip="Click to more info"
            (click)="popoverTargetRiskLevel.toggle()"
            [satPopoverAnchor]="popoverTargetRiskLevel"
            class="move-end"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_14774_17883)">
                <circle cx="10" cy="10" r="9" fill="white" />
                <path
                  d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875ZM8.125 9.6875C8.125 9.43886 8.22377 9.2004 8.39959 9.02459C8.5754 8.84877 8.81386 8.75 9.0625 8.75H10.3125C10.5611 8.75 10.7996 8.84877 10.9754 9.02459C11.1512 9.2004 11.25 9.43886 11.25 9.6875V13.125H11.5625C11.8111 13.125 12.0496 13.2238 12.2254 13.3996C12.4012 13.5754 12.5 13.8139 12.5 14.0625C12.5 14.3111 12.4012 14.5496 12.2254 14.7254C12.0496 14.9012 11.8111 15 11.5625 15H9.0625C8.81386 15 8.5754 14.9012 8.39959 14.7254C8.22377 14.5496 8.125 14.3111 8.125 14.0625C8.125 13.8139 8.22377 13.5754 8.39959 13.3996C8.5754 13.2238 8.81386 13.125 9.0625 13.125H9.375V10.625H9.0625C8.81386 10.625 8.5754 10.5262 8.39959 10.3504C8.22377 10.1746 8.125 9.93614 8.125 9.6875ZM10 7.5C9.66848 7.5 9.35054 7.3683 9.11612 7.13388C8.8817 6.89946 8.75 6.58152 8.75 6.25C8.75 5.91848 8.8817 5.60054 9.11612 5.36612C9.35054 5.1317 9.66848 5 10 5C10.3315 5 10.6495 5.1317 10.8839 5.36612C11.1183 5.60054 11.25 5.91848 11.25 6.25C11.25 6.58152 11.1183 6.89946 10.8839 7.13388C10.6495 7.3683 10.3315 7.5 10 7.5Z"
                  fill="#808080"
                />
              </g>
              <defs>
                <clipPath id="clip0_14774_17883">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </mat-icon>

          <sat-popover #popoverTargetRiskLevel hasBackdrop>
            <div class="popover-2">
              <div class="popover-2__head">
                <h3 class="popover-2__h3">Target Risk level</h3>

                <mat-icon
                  class="popover-2__icon"
                  (click)="popoverTargetRiskLevel.close()"
                  >close</mat-icon
                >
              </div>
              <p class="popover-2__text">
                Targeted level of risk within an agreed time horizon, guided by
                the risk appetite.
              </p>
              <a
                [routerLink]="['/glossary']"
                [queryParams]="{ search: 'Target risk level', char: 't' }"
                target="_blank"
                class="btn btn--outline"
              >
                Learn more &rarr;
              </a>
            </div>
          </sat-popover>
        </div>
      </div>

      <div>
        <mat-icon
          class="icon"
          matTooltip="If targets cannot be set, by ticking this button the system will retain the current likelihood and impact risk levels"
          >help_outline</mat-icon
        >
        <mat-label class="risk-report-form-field__label--Request"
          >Targets cannot be set</mat-label
        >
        <mat-checkbox
          type="checkbox"
          labelPosition="before"
          formControlName="request_assistance"
          (ngModelChange)="checkedReqAssistance()"
        ></mat-checkbox>
      </div>

      <!-- //Due date to reach target level*----- -->

      <div class="risk-report-form-field field-1 m-b--3">
        <mat-label class="risk-report-form-field__label"
          >Due date to reach target level<span
            *ngIf="dueDateRequired"
            class="risk-report-form-field__label__star"
            >*</span
          ></mat-label
        >
        <!-- <mat-form-field
          class="form-dialog-content__field n"
          floatLabel="always"
          hideRequiredMarker
          appearance="outline" 
        >-->
        <div class="risk-report-form-field-icon-box">
          <input
            class="risk-report-form-field-box__input cursor-input__dueDate report-form-field-size__dueDate"
            matInput
            placeholder="Due date"
            formControlName="due_date"
            [matDatepicker]="picker"
          />

          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <mat-error
          *ngIf="newRiskForm.controls['due_date'].errors?.['required'] && this.newRiskForm.controls['due_date'].value == null && this.newRiskForm.controls['request_assistance'].value == false && (newRiskForm.controls['due_date'].dirty || newRiskForm.controls['due_date'].touched)"
          >Due date is required</mat-error
        >

        <mat-error
          *ngIf="newRiskForm.controls['due_date'].errors?.['past_date'] && this.newRiskForm.controls['due_date'].value != null"
          >Due date can't be in the past</mat-error
        >
        <mat-error
          *ngIf="newRiskForm.controls['due_date'].errors?.['past_date_created'] && this.newRiskForm.controls['due_date'].value != null"
          >Due date can't be in the past</mat-error
        >

        <!-- </mat-form-field> -->
      </div>
    </form>

    <!-- //Actions /Controls to manage risks*----- -->

    <div class="risk-report-table-actinos-controls-container m-b--3">
      <div class="risk-report-actions-controls-table-box">
        <div class="risk-report-actions-controls-table-box__text-box">
          <p class="risk-report-actions-controls-table-box__text-box__text">
            Actions /Controls to manage risks
          </p>
        </div>

        <div class="table-box">
          <table
            mat-table
            [dataSource]="proposed"
            class="mat-elevation-z8"
            matSort
          >
            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

            <!-- Actions/Controls description Column -->
            <ng-container matColumnDef="Mitigation Description">
              <th mat-header-cell *matHeaderCellDef>
                <span class="h4">Actions/Controls description</span>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.description }}
              </td>
            </ng-container>

            <!-- Status of Action Column -->
            <ng-container matColumnDef="Status of Action">
              <th mat-header-cell *matHeaderCellDef>
                <span class="h4">Status of Action</span>
              </th>
              <td mat-cell *matCellDef="let element">
                {{
                  element?.status?.title ||
                    getStatusByID(element?.mitigation_status_id)
                }}
              </td>
            </ng-container>

            <!-- Action Name Column -->
            <ng-container matColumnDef="Actions">
              <th mat-header-cell *matHeaderCellDef>
                <span class="h5">Actions</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="m">
                  <button
                    matTooltip="Edit"
                    style="
                      border: none;
                      cursor: pointer;
                      background-color: #ffffff;
                    "
                    (click)="editProposed(element)"
                  >
                    <mat-icon class="user-management-table__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="22"
                        viewBox="0 0 23 22"
                        fill="none"
                      >
                        <path
                          d="M18.1917 8.30937L14.2958 4.63437L15.5792 3.40938C15.9306 3.07396 16.3623 2.90625 16.8744 2.90625C17.3859 2.90625 17.8174 3.07396 18.1687 3.40938L19.4521 4.63437C19.8035 4.96979 19.9868 5.37462 20.0021 5.84887C20.0174 6.32254 19.8493 6.72708 19.4979 7.0625L18.1917 8.30937ZM16.8625 9.6L7.14583 18.875H3.25V15.1562L12.9667 5.88125L16.8625 9.6Z"
                          fill="#436280"
                        /></svg
                    ></mat-icon>
                  </button>

                  <button
                    matTooltip="Delete"
                    style="
                      border: none;
                      cursor: pointer;
                      background-color: #ffffff;
                    "
                    (click)="removeProposed(element)"
                  >
                    <mat-icon class="user-management-table__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M18.2188 4.4375H15.2656V3.45312C15.2635 2.84462 15.0208 2.26166 14.5905 1.83138C14.1602 1.4011 13.5773 1.15841 12.9688 1.15625H9.03125C8.42275 1.15841 7.83978 1.4011 7.4095 1.83138C6.97922 2.26166 6.73654 2.84462 6.73438 3.45312V4.4375H3.78125C3.52018 4.4375 3.2698 4.54121 3.08519 4.72582C2.90059 4.91042 2.79688 5.1608 2.79688 5.42188C2.79688 5.68295 2.90059 5.93333 3.08519 6.11793C3.2698 6.30254 3.52018 6.40625 3.78125 6.40625H4.10938V17.5625C4.11153 17.997 4.28507 18.413 4.59229 18.7202C4.8995 19.0274 5.31554 19.201 5.75 19.2031H16.25C16.6845 19.201 17.1005 19.0274 17.4077 18.7202C17.7149 18.413 17.8885 17.997 17.8906 17.5625V6.40625H18.2188C18.4798 6.40625 18.7302 6.30254 18.9148 6.11793C19.0994 5.93333 19.2031 5.68295 19.2031 5.42188C19.2031 5.1608 19.0994 4.91042 18.9148 4.72582C18.7302 4.54121 18.4798 4.4375 18.2188 4.4375ZM8.70312 3.45312C8.70312 3.3661 8.73769 3.28264 8.79923 3.22111C8.86077 3.15957 8.94423 3.125 9.03125 3.125H12.9688C13.0558 3.125 13.1392 3.15957 13.2008 3.22111C13.2623 3.28264 13.2969 3.3661 13.2969 3.45312V4.4375H8.70312V3.45312ZM15.9219 17.2344H6.07812V6.40625H15.9219V17.2344ZM10.0156 9.03125V14.2812C10.0156 14.5423 9.91191 14.7927 9.72731 14.9773C9.5427 15.1619 9.29232 15.2656 9.03125 15.2656C8.77018 15.2656 8.5198 15.1619 8.33519 14.9773C8.15059 14.7927 8.04688 14.5423 8.04688 14.2812V9.03125C8.04688 8.77018 8.15059 8.5198 8.33519 8.33519C8.5198 8.15059 8.77018 8.04688 9.03125 8.04688C9.29232 8.04688 9.5427 8.15059 9.72731 8.33519C9.91191 8.5198 10.0156 8.77018 10.0156 9.03125ZM13.9531 9.03125V14.2812C13.9531 14.5423 13.8494 14.7927 13.6648 14.9773C13.4802 15.1619 13.2298 15.2656 12.9688 15.2656C12.7077 15.2656 12.4573 15.1619 12.2727 14.9773C12.0881 14.7927 11.9844 14.5423 11.9844 14.2812V9.03125C11.9844 8.77018 12.0881 8.5198 12.2727 8.33519C12.4573 8.15059 12.7077 8.04688 12.9688 8.04688C13.2298 8.04688 13.4802 8.15059 13.6648 8.33519C13.8494 8.5198 13.9531 8.77018 13.9531 9.03125Z"
                          fill="#436280"
                        /></svg
                    ></mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr
              class="table-header"
              mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
              class="table-header"
              mat-row
              *matRowDef="let row; columns: displayedColumns"
            ></tr>
          </table>
          <p class="errorMessage">{{ errorMessage }}</p>
        </div>

        <!-- Add mitigation-btn -->
        <div class="risk-report-actions-controls-btn-box">
          <button
            class="parameters-settings__button-box__btn btn-primary-size"
            (click)="openNewProposedDialog()"
            mat-raised-button
            color="primary"
          >
            <div class="create-flex-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                  fill="white"
                />
              </svg>
              <span class="add-text">Add</span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <!--  Save-btn -->
    <div class="risk-report-form-save-btn-box">
      <button
        class="risk-report-form-save-btn-box__btn"
        [ngClass]="{
          disabled: clicked === true
        }"
        (click)="submit(); clicked = true"
        [disabled]="clicked"
        mat-raised-button
      >
        Save
      </button>
    </div>
  </div>
</div>

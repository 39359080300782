<!-- user-management-content -->

<div class="user-management__icon-box">
  <mat-icon class="head-icon">archive</mat-icon>
  <span class="user-management__icon-box__title">Archive management </span>
</div>

<div class="user-management__text-box--1">
  <!-- <p class="user-management__text-box__title">
      Welcome to the user management panel, here you can manage the users and
      their roles within the platform.
    </p> -->
</div>

<div class="user-management__content--1">

  <div class="user-management__container">

    <div class="form-container">

      <div class="sync">
        <button class="user-management__button-box__btn btn-sync" mat-raised-button (click)="archiveData()">
          <mat-icon class="btn-icon">archive</mat-icon>
          <span class="user-management__button-box__title">Archive</span>
        </button>
      </div>
    </div>

    <div class="table-box mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 risk-managment-table" matSort>
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- iNITCode Column -->
        <ng-container matColumnDef="INIT-ID">
          <th mat-header-cell *matHeaderCellDef>
            <div class="mtable">ID</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="mtable">{{ element["official_code"] }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Science Programs name">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h1">Name</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element["name"] }}
          </td>
        </ng-container>

        <!-- riskCategory Column -->
        <ng-container matColumnDef="Risk Category">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-3">Risk category</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="td-3-h">{{
              filterCategories(element["risks"])
              }}</span>
          </td>
        </ng-container>

        <!-- numOfRisks Column -->
        <ng-container matColumnDef="Number of risks">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h3">Num of risks</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="td-h">{{ element["risks"].length }}</span>
          </td>
        </ng-container>

        <!-- myRole Column -->
        <ng-container matColumnDef="My Role">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h4">My role</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ filterRoles(element["roles"]) }}
          </td>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h4">Status</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.status_by_phase }}
          </td>
        </ng-container>

        <!-- Help Requested Column -->
        <ng-container matColumnDef="Help requested">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h4">Targets not set</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ filterReqAssistance(element.risks) }}
          </td>
        </ng-container>

        <!-- Actions Name Column -->
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h5">Actions</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="m">
                <mat-checkbox class="example-margin" (change)="checkInit($event, element.id)"></mat-checkbox>
            </div>
          </td>
        </ng-container>

        <tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="table-header" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
<div class="risk-report-container">
  <div class="risk-report-content">
    <nav class="risk-nav-main">
      <ul class="risk-nav-list">
        <li>
          <a class="risk-nav-link" routerLink="/home">Programs</a>
        </li>
        <li>
          <a routerLink="''" class="risk-nav-link">></a>
        </li>
        <li>
          <a
            routerLink="/home/{{ sciencePrograms?.id }}/{{
              sciencePrograms?.official_code
            }}"
            class="risk-nav-link"
            >&nbsp;Risk report<span class="line">&nbsp;/</span>&nbsp;{{
              sciencePrograms?.official_code
            }}</a
          >
        </li>
      </ul>
    </nav>

    <div class="risk-report-box">
      <div class="risk-report-title-box">
        <h1 class="risk-report-title-box__title">Risk report</h1>
        <div class="assign-org">
          <span *ngFor="let org of assignOrg; let last = last">
            {{ org.acronym }}<span *ngIf="!last">/</span><br />
          </span>          
        </div>
      </div>

      <div class="risk-report-btn-box">
        <!-- btn-1 -->
        <div class="risk-report-button-box">
          <button
            class="risk-report-button-box__btn btn--animated btn--white btn"
            routerLink="./team-members"
            mat-raised-button
          >
            Team members
          </button>
        </div>
        <!-- btn-2 -->
        <div class="risk-report-button-box">
          <button
            class="risk-report-button-box__btn btn--animated btn--white btn"
            routerLink="./versions"
            mat-raised-button
          >
            Submitted versions
          </button>
        </div>
        <!-- btn-3 -->
        <div class="risk-report-button-box">
          <button
            class="risk-report-button-box__btn btn--animated btn--white btn"
            routerLink="./risk-dashboard"
            mat-raised-button
          >
            Risk dashboard
          </button>
        </div>

        <!-- btn-4 -->

        <div class="risk-report-button-box" [matTooltip]="toolTipMessage">
          <button
            [disabled]="
              !canPublish() ||
              this.NumberOfRisks <= 0 ||
              publishStatus.value == '0' ||
              (AllRisk?.redundentRisk?.length != 0 &&
                AllRisk?.risks?.length == 0) ||
              this.isTrue
            "
            class="risk-report-button-box__btn--5 btn--animated btn--dark btn"
            (click)="publish(sciencePrograms.id)"
            [ngClass]="{
              disabled:
                !canPublish() ||
                this.NumberOfRisks <= 0 ||
                publishStatus.value == '0' ||
                (AllRisk?.redundentRisk?.length != 0 &&
                  AllRisk?.risks?.length == 0) ||
                this.isTrue
            }"
            mat-raised-button
          >
            Submit
          </button>
        </div>

        <!-- btn-5 -->
        <div class="risk-report-button-box">
          <button
            [disabled]="!canEdit()"
            [ngClass]="{ disabled: !canEdit() }"
            class="risk-report-button-box__btn--4 btn--animated btn--white-2 btn"
            routerLink="./create-risk"
            mat-raised-button
          >
            <mat-icon class="create-risk">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
              >
                <path
                  d="M11.5556 0H1.44444C0.642778 0 0 0.65 0 1.44444V11.5556C0 12.35 0.642778 13 1.44444 13H11.5556C12.35 13 13 12.35 13 11.5556V1.44444C13 0.65 12.35 0 11.5556 0ZM11.5556 11.5556H1.44444V1.44444H11.5556V11.5556ZM5.77778 10.1111H7.22222V7.22222H10.1111V5.77778H7.22222V2.88889H5.77778V5.77778H2.88889V7.22222H5.77778V10.1111Z"
                  fill="white"
                />
              </svg>
            </mat-icon>
            Create risk
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="reload">
      <p
        class="zzz btn--animated"
        *ngIf="latest_version?.status == false || !latest_version"
      >
        This is a draft version until it get submitted. last update at
        {{ sciencePrograms?.last_updated_date | date : "short" }}
      </p>
      <p
        class="zzz btn--animated"
        *ngIf="latest_version?.status == true"
        style="float: right; margin: 0 !important"
      >
        This Science Programs risks submitted on
        {{ latest_version?.submit_date | date : "short" }}
        <mat-icon
          matTooltip="Any changes to the submitted version will  convert it into the draft mode."
          >help_outline</mat-icon
        >
      </p>
    </div>

    <app-search-risk
      class="btn--animated"
      *ngIf="sciencePrograms?.id"
      [program_id]="sciencePrograms.id"
      [AllRisk]="AllRisk"
      (filters)="filter($event)"
      (exportPdf)="exportPdf()"
    ></app-search-risk>

    <app-risk-report-table
      [my_risks]="my_risks"
      [savePdf]="savePdf"
      [dataSource]="dataSource"
      [dataSourceForPdf]="dataSourceForPdf"
      [AllRisk]="AllRisk"
      (refresh)="refresh($event)"
    ></app-risk-report-table>
  </div>
</div>

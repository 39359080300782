<div class="about-p">
  <section class="section-about-risk">
    <div class="about-risk-container-1">
      <div class="about-risk__title-box">
        <h1 class="about-risk__title-box--title">ABOUT RISK</h1>
      </div>

      <div class="about-risk__text-box">
        <p class="about-risk__text-box--text">
          Risk is not inherently a bad thing. CGIAR recognizes that taking and
          managing risks is an integral part of delivering on its strategy and
          that effective risk management is critical to its success and key
          element of good governance. Managing risk is an integral part in
          managing any organization. Good risk management is not only about
          mitigating risk. Good risk management is equally about good risk
          taking.
          <!-- We believe there is a better way to do marketing. A more valuable way
        where customers are
        <span class="about-risk__text-box--text--1"
          >earned rather than bought</span
        >. We're obsessively passionate about it, and our mission is to help
        people achieve it. We focus on
        <span class="about-risk__text-box--text--2"
          >search engine optimization</span
        >. It's one of the least understood and least transparent aspects of
        great marketing, and we see that as an opportunity. We're excited to
        simplify SEO for everyone
        <span class="about-risk__text-box--text--3">through our software</span>,
        education, and community. -->
        </p>
      </div>
    </div>
  </section>

  <!-- ////////////////////////////////////////////////////////////////////////// -->

  <!-- <div class="btn--animated"> -->
  <section class="section-about-risk--2">
    <div class="about-risk-container">
      <!-- <div class="our-founding__img-box">
        <img
          src="../../assets/shared-image/Vector-our-founding.svg"
          class="our-founding__img-box__img"
          alt="~/Frontend/images/question-circular-button.svg"
        />
      </div> -->
      <div class="our-founding__title-box">
        <h1 class="our-founding__text-box__title">Introduction</h1>
      </div>

      <div class="about-risk__text-box-2">
        <!-- <h2 class="our-founding__text-box__title"></h2> -->

        <h4 class="our-founding-titles">Why do we manage risks?</h4>
        <ul class="our-founding-main-list">
          <li class="our-founding-main-list__list">
            Increased ability to reach objectives in an effective and consistent
            way
          </li>
          <li class="our-founding-main-list__list">
            Better informed strategic decision taking with respect to
            opportunities and threats
          </li>
          <li class="our-founding-main-list__list">
            Maintain compliance and support governance and assurance activities
          </li>
        </ul>

        <p class="our-founding-titles">Best practices</p>

        <p class="our-founding__text-box__text">
          Everyone deals with risk and uncertainty every day in their personal
          and professional lives, often intuitively, but an explicit risk
          management methodology and process is an integral part of project
          management best practice. Risk management starts with objectives,
          assessed in terms of effect on objectives, not a standalone matter. A
          structured and objectives-focused risk management methodology and
          process helps teams achieve their goals. Specifically, it helps teams
          to:
        </p>

        <ul class="our-founding-main-list">
          <li class="our-founding-main-list__list">
            Identify the most important risks to achieving their goals
          </li>
          <li class="our-founding-main-list__list">
            Identify risk management actions in relation to likelihood and/or
            impact of key risks, track their progress, either to mitigate risk
            or support risk taking needed to realise opportunities
          </li>
          <li class="our-founding-main-list__list">
            Ensure the responsibility for risk monitoring and actions is
            allocated to the right individuals
          </li>
          <li class="our-founding-main-list__list">
            Escalate or transfer risks which the team identifies as being
            important, but which the team cannot sufficiently manage
          </li>

          <li class="our-founding-main-list__list">
            Provide visibility on risks, including actions and owners and
            facilitates communication
          </li>
        </ul>

        <p class="our-founding__text-box__text">
          A consistent and structured approach, standardized risk terminology
          and reporting helps to convey information effectively to key
          stakeholders, therefore create trust and instil confidence especially
          in the current environment which demands greater scrutiny than ever
          before about how risk is addressed and managed. It helps portfolio
          managers and senior leaders:
        </p>

        <ul class="our-founding-main-list">
          <li class="our-founding-main-list__list">
            understand the most important overall risks facing an organization.
          </li>
          <li class="our-founding-main-list__list">
            identify systemic risks which may be affecting multiple teams.
          </li>
          <li class="our-founding-main-list__list">
            identify risks which have the potential to cascade across teams.
          </li>
          <li class="our-founding-main-list__list">
            plan proportionate actions to manage organizational risks.
          </li>
        </ul>

        <!-- <p class="our-founding__text-box__text">
        Remember that there is a possibility that the result you want to report
        has already been entered into the PRMS Reporting tool. If this is the
        case, it is only necessary to map the result to your Initiative. There
        is no need to enter it as a new result. Please check the
        <span class="our-founding__text-box__text--1">results section</span>
        to see if your result has been previously entered.
      </p> -->
      </div>
    </div>
  </section>

  <div class="our-founding-Polygon">
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="27" viewBox="0 0 46 27" fill="none">
      <path d="M23 27L45.5167 0H0.483339L23 27Z" fill="#A3BAD2" />
    </svg>
  </div>

  <section class="section-our-founding--2">
    <!-- ////////////////////////////////////////////////////////////////////////// -->

    <div class="about-risk-container">
      <div class="our-founding__title-box">
        <h1 class="our-founding__text-box__title">
          What are the key elements of risk management?
        </h1>
      </div>

      <div class="about-risk__text-box-2">
        <p class="our-founding__text-box__text">
          According to the ISO 31000:2018 the international standard on Risk
          management, the three main elements of risk management are the
          Principles, Framework and Process (including the risk assessment
          process). Principles and Framework are set out in the Appendix. The
          Risk management process, adapted from ISO 31000 can be summarized as
          follows:
        </p>
        <div class="about-risk__text-box step-img-box">
          <img src="../../assets/shared-image/risk-management-module.svg" class="our-founding__img-box__img"
            alt="~/Frontend/images/question-circular-button.svg" />
        </div>

        <p class="our-founding__text-box__text">
          The different steps within the risk assessment process include:
          identify risks, analyze risk by assessing risk likelihood and
          consequences/impact, evaluate level of risk to decide on actions
          needed in relation to risk appetite and assign risk owners.
        </p>
      </div>
    </div>
  </section>

  <section class="section-about-risk-2">
    <div class="about-risk-container">
      <div class="our-founding__title-box-2">
        <h1 class="our-founding__text-box__title">
          Risk management in Programs and Accelerators
        </h1>
      </div>

      <div class="about-risk__text-box-2">
        <p class="our-founding__text-box__text">
          Program and Accelerator Directors/Deputies, and their teams, will be managing risk on a day-to-day basis as
          part of their business-as-usual approach to project management. It is also important to have a standard
          approach to risk reporting and escalation, to allow portfolio managers to monitor and manage the balance of
          risks across the portfolio, and make sure resources and support are provided, and decisions made at an
          appropriate time. This standard approach to concepts, definitions and reporting is what this guidance note
          focuses on.
        </p>

        <p class="our-founding-titles">Risks to impact contribution</p>

        <p class="our-founding__text-box__text">
          The risk scope of this guidance is around risks to an Program and Accelerator’s contribution to impact. These
          are the processes, outlined in the Program and Accelerator’s Theories of Change, by which the Program and
          Accelerator, with partners, contributes to processes which are expected to ultimately lead to impact within
          one or several of the five Impact Areas set out in the CGIAR’s 2030 Research and Innovation Strategy. The most
          significant risks to impact contribution may fall within the Program and Accelerator’s sphere of control,
          sphere of influence or sphere of interest. This may mean that, for some risks, the Program and Accelerator
          team, or Action Area leads, may be able to effectively manage or mitigate the risk, whilst for others, risk
          management or mitigation may more effectively be carried out by partners. For some risks, management or
          mitigation may not be feasible or proportionate. It is still important to identify and monitor such risks, as
          this will help inform Program and Accelerator decision making.
        </p>

        <p class="our-founding-titles">
          The link between risks to impact, and assumptions in Programs and Accelerators’ theories of change
        </p>

        <p class="our-founding__text-box__text">
          An Program and Accelerator’s Theories of Change sketch out how it anticipates its activities could ultimately
          contribute, with partners, to impact. The causal pathways within theories of change are underpinned by
          assumptions. There is a conceptual link between the assumptions in a theory of change, and the risks to impact
          contribution. When considering or reviewing an Program and Accelerator’s top risks to impact contribution,
          Program and Accelerator teams may wish to consider which assumptions within their theories of change are most
          critical ('load-bearing assumptions'). They may also wish to consider whether any of these critical
          assumptions face a particularly high degree of uncertainty. Critical, high-uncertainty assumptions in an
          Program and Accelerator’s Theory of Change may usefully be treated as an explicit risk to be actively managed
          or monitored within an Program and Accelerator’s risk register and mentioned in risk reporting. Depending on
          the assumption in question, active management or monitoring may take the form of purposeful MELIA activity, or
          specific activities within the team or with partners.
        </p>
      </div>
    </div>
  </section>

  <!-- <section class="section-variety-tools">
    <div class="about-risk__variety-tools">
      <div class="variety-tools__title-box">
        <h2 class="variety-tools__title-box--title">Variety of Tools</h2>
      </div>

      <div class="variety-tools__content">
        <div class="variety-tools-el tools-e1">
          <div class="variety-tools__logo-box">
            <a href=" https://reporting.cgiar.org/login" target="_blank"
              class="variety-tools__logo-box__title cool-link">reporting</a>
          </div>
          <div class="variety-tools__text-box">
            <p class="variety-tools__text-box__text">
              The Performance and Results Management System (PRMS) website is an
              online collaborative working platform that supports the reporting
              of results against the CGIAR 2030 Research and Innovation
              Strategy.
            </p>
          </div>
        </div>

        <div class="variety-tools-el tools-e2">
          <div class="variety-tools__logo-box">
            <a href="https://planning.cgiar.org/auth" target="_blank" class="variety-tools__logo-box__title cool-link">
              Planning
            </a>
          </div>
          <div class="variety-tools__text-box">
            <p class="variety-tools__text-box__text">
              The Planning Module is an online platform designed to support
              Initiatives and Impact Platforms develop annual Plans of Results
              and Budgets against the approved budgets and targeted results as
              outlined in the Inititiative and Impact Platforms Theory of
              Change. The Planning module provides opportunity for Initiatives
              and Impact Platforms to set up how the projects will be managed,
              what needs to be accomplished, by when, by whom, and with what
              budget resources.
            </p>
          </div>
        </div>
        <div class="variety-tools-el tools-e3">
          <div class="variety-tools__logo-box">
            <a href="https://toc.mel.cgiar.org/" target="_blank"
              class="variety-tools__logo-box__title cool-link">TOC</a>
          </div>
          <div class="variety-tools__text-box">
            <p class="variety-tools__text-box__text">
              The Theory of Change Tool (ToC Tool) is an online platform
              designed to support the Initiatives, Platforms, Science Group
              Projects and Bilaterals with the development of their Theories of
              Change against the CGIAR 2030 Research and Innovation Strategy.
            </p>
          </div>
        </div>
        <div class="variety-tools-el tools-e4">
          <div class="variety-tools__logo-box">
            <a href="https://initiatives.cgiar.org/" target="_blank"
              class="variety-tools__logo-box__title cool-link">OST</a>
          </div>
          <div class="variety-tools__text-box">
            <p class="variety-tools__text-box__text">
              The Online Submission Tool (OST) is a collaborative working
              platform that collects data at the proposal stage and makes it
              available to provide further monitoring, reporting and delivery of
              the CGIAR 2030 Research and Innovation Strategy
            </p>
          </div>
        </div>
        <div class="variety-tools-el tools-e5">
          <div class="variety-tools__logo-box">
            <a href="https://clarisa.cgiar.org/landing-page/home" target="_blank"
              class="variety-tools__logo-box__title cool-link">CLARISA</a>
          </div>
          <div class="variety-tools__text-box">
            <p class="variety-tools__text-box__text">
              CLARISA is the CGIAR Level Agricultural Results Interoperable
              System Architecture, a web service that helps collect and
              transform raw data of CGIAR research and activities into
              standardized and aggregated information. The service supports the
              work within One CGIAR and reveals its impacts on development –
              reducing poverty, improving food and nutrition security for
              health, and improving natural resources and ecosystem services.
            </p>
          </div>
        </div>
        <div class="variety-tools-el tools-e6">
          <div class="variety-tools__logo-box">
            <a href="https://risk.cgiar.org/about" target="_blank"
              class="variety-tools__logo-box__title cool-link">Risk</a>
          </div>
          <div class="variety-tools__text-box">
            <p class="variety-tools__text-box__text">
              The Risk Management Module is a component of the Performance and
              Results Management System (PRMS) developed to assist the
              Initiatives in developing, managing and reporting risks related to
              the delivery of the CGIAR 2030 Research and Innovation Strategy.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</div>
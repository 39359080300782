<div class="published-versions-container">
    <nav class="risk-nav-main">
        <ul class="risk-nav-list">
          <li>
            <a class="risk-nav-link" routerLink="/archive">Archive</a>
          </li>
          <li>
            <a routerLink="''" class="risk-nav-link">></a>
          </li>
          <li>
            <a
              class="risk-nav-link"
              >{{
                officalCode
              }}</a
            >
          </li>
          <li>
            <a routerLink="''" class="risk-nav-link">></a>
          </li>
          <li>
            <a
              class="risk-nav-link"
              >Submitted versions</a
            >
          </li>
          <li>
            <a routerLink="''" class="risk-nav-link">></a>
          </li>
          <li>
            <a
              class="risk-nav-link"
              >Version - {{ versionId }}</a
            >
          </li>
        </ul>
      </nav>
  
    <div class="published-versions-content">
      <div class="published-versions-title-box">
        <h1 class="published-versions-title-box__title">
          {{ scienceProgramsName }} Version - {{ versionId }}
        </h1>
      </div>
  
 
      <div class="risk-management-export"> 
        <div class="risk-management-export-box">
          <div class="risk-management-export-box">
            <div>
              <mat-label>Display risks with targets not set</mat-label>
              <mat-checkbox
                labelPosition="before"
                (change)="risksNeedHelp($event)"
              ></mat-checkbox>
            </div>
            <div>
              <button
                class="risk-management-export-submitted-btn"
                mat-raised-button
                (click)="export()"
              >
                <mat-icon class="risk-management-export-box-icon">
                  <svg
                    class="risk-management-export-box-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="21"
                    viewBox="0 0 22 21"
                    fill="none"
                  >
                    <path
                      d="M5.65951 17.6484C5.15534 17.6484 4.72389 17.4772 4.36517 17.1348C4.00584 16.7918 3.82617 16.3797 3.82617 15.8984V13.2734H5.65951V15.8984H16.6595V13.2734H18.4928V15.8984C18.4928 16.3797 18.3135 16.7918 17.9548 17.1348C17.5954 17.4772 17.1637 17.6484 16.6595 17.6484H5.65951ZM11.1595 14.1484L6.57617 9.77344L7.8595 8.50469L10.2428 10.7797V3.64844H12.0762V10.7797L14.4595 8.50469L15.7428 9.77344L11.1595 14.1484Z"
                      fill="white"
                    /></svg
                ></mat-icon>
  
                Export data
              </button>
            </div>
          </div>
        </div>
      </div> 
  
      
  
      <!-- step-3 -->
      <app-risk-report-table
        [showingVersion]="true"
        [dataSource]="dataSource"
        [showReduntent]="showReduntent"
        [titlePage]="scienceProgramsName + 'Version'"
      >
      </app-risk-report-table>
    </div>
  </div>
  
<!-- user-management-content -->

<div class="user-management__icon-box">
    <mat-icon class="head-icon">sync</mat-icon>
    <span class="user-management__icon-box__title">Sync clarisa</span>
  </div>
  
  <div class="user-management__text-box--1">
    <!-- <p class="user-management__text-box__title">
        Welcome to the user management panel, here you can manage the users and
        their roles within the platform.
      </p> -->
  </div>
  
  <div class="user-management__content--1">
  
    <div class="user-management__container">
  
      <div class="form-container">
  
        <div class="sync">
          <button class="user-management__button-box__btn btn-sync" mat-raised-button (click)="syncData()">
            <mat-icon class="btn-icon">sync</mat-icon>
            <span class="user-management__button-box__title">Sync</span>
          </button>
        </div>
      </div>
  
      <div class="table-box mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 risk-managment-table" matSort>
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
  
          <!-- iNITCode Column -->
          <ng-container matColumnDef="INIT-ID">
            <th mat-header-cell *matHeaderCellDef>
              <div class="mtable">ID</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="mtable">{{ element["code"] }}</div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="Science programs Name">
            <th mat-header-cell *matHeaderCellDef>
              <span class="h1">Science programs Name</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element["name"] != null ? element["name"] : element["short_name"] }}
            </td>
          </ng-container>
  
    
  
          <!-- Actions Name Column -->
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef>
              <span class="h5">Actions</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="m">
                  <mat-checkbox  class="example-margin" (change)="checkInit($event, element.code)"></mat-checkbox>
              </div>
            </td>
          </ng-container>
  
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
              No data found.
            </td>
          </tr>
          <tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="table-header" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
<!-- step-2 -->
<div class="form-container">
  <form [formGroup]="filterForm" class="search">
    <div class="sec-1">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label"
          >ID</mat-label
        >

        <input
          formControlName="initiative_id"
          hideToggle="true"
          class="search__input"
          matInput
          placeholder="ID"
          #input
        />

        <button
          style="border: none; cursor: pointer; background-color: #ffffff12"
          class="search__button"
        >
          <mat-icon class="search__icon">
            <img
              class="search__icon"
              src="../../assets/shared-image/Vector-search.svg"
              alt="~/Frontend/images/icon _ search-icon"
          /></mat-icon>
        </button>
      </div>
    </div>
    <div class="sec-2">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label"
          >Name</mat-label
        >

        <input
          formControlName="name"
          hideToggle="true"
          class="search__input"
          matInput
          placeholder="Search"
          #input
        />

        <button
          style="border: none; cursor: pointer; background-color: #ffffff12"
          class="search__button"
        >
          <mat-icon class="search__icon">
            <img
              class="search__icon"
              src="../../assets/shared-image/Vector-search.svg"
              alt="~/Frontend/images/icon _ search-icon"
          /></mat-icon>
        </button>
      </div>
    </div>

    <!-- //Risk category----- -->
    <div class="sec-3">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label"
          >Risk category</mat-label
        >

        <mat-select
          [multiple]="true"
          formControlName="category"
          panelClass="x"
          class="risk-management-form-field-box__input-3 aa"
          [placeholder]="'All'"
          id="select-where"
          required
        >
          <ng-container *ngFor="let riskCat of categories">
            <mat-option *ngIf="riskCat.disabled == 0" [value]="riskCat.id">
              {{ riskCat.title }}

              <mat-icon [matTooltip]="riskCat.description" matSuffix
                >help_outline</mat-icon
              >
            </mat-option>
          </ng-container>
        </mat-select>
      </div>
    </div>

    <!-- //Status----- -->
    <div class="sec-4">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label">Status</mat-label>

        <mat-select
          formControlName="status"
          class="risk-management-form-field-box__input-3"
          [placeholder]="'All'"
          id="select-where"
          required
        >
          <mat-option>All</mat-option>
          <mat-option
            *ngFor="let statusOption of status"
            [value]="statusOption.value"
          >
            {{ statusOption.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>


    <div class="sec-5">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label">Phase</mat-label>

        <mat-select
          (valueChange)="selectedPhase($event)"
          formControlName="phase_id"
          class="risk-management-form-field-box__input-3"
          [placeholder]="'All'"
          id="select-where"
        >
          <mat-option
          class="phase"
            *ngFor="let Phase of phases?.result"
            [value]="Phase.id"
          >
            {{ Phase.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>


    <!-- //My role----- -->
    <!-- <div class="set-flex"> -->
    <div class="sec-4">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label">My role</mat-label>

        <mat-select
          [multiple]="true"
          formControlName="my_role"
          class="risk-management-form-field-box__input-3"
          [placeholder]="'All'"
          id="select-where"
          required
        >
          <mat-option *ngFor="let item of roles" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <!-- //Sort----- -->
    <!-- <div class="sec-6">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label">Sort</mat-label>

        <mat-select
          formControlName="sort"
          class="risk-management-form-field-box__input-3"
          [placeholder]="'All'"
          id="select-where"
          required
        >
          <mat-option>All</mat-option>
          <mat-option *ngFor="let item of sort" [value]="item.value">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </div>
    </div> -->
    <!-- //Reset Button----- -->

    <!-- </div> -->
  </form>
</div>






<!-- step-3 -->

<!-- start change about reisk-management btn-export-to-excel -->
<div class="footer-sec">
  <div class="content-1">
    <!-- <form class="risk-management-export-box"> -->
    <div>
      <mat-label class="initiatives-text-checkbox">My Programs</mat-label>
      <mat-checkbox
        labelPosition="before"
        [(ngModel)]="myIni"
        (ngModelChange)="myIniChange()"
      >
      </mat-checkbox>
    </div>
    <!-- //Reset Button----- -->
    <div class="sec-7">
      <button
        class="risk-management-reset-btn"
        mat-raised-button
        (click)="resetForm()"
      >
        Reset
      </button>
    </div>
    <div>
      <button
        class="risk-management-export-btn"
        mat-raised-button
        (click)="export()"
      >
        <mat-icon class="risk-management-export-box-icon">
          <svg
            class="risk-management-export-box-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
          >
            <path
              d="M5.65951 17.6484C5.15534 17.6484 4.72389 17.4772 4.36517 17.1348C4.00584 16.7918 3.82617 16.3797 3.82617 15.8984V13.2734H5.65951V15.8984H16.6595V13.2734H18.4928V15.8984C18.4928 16.3797 18.3135 16.7918 17.9548 17.1348C17.5954 17.4772 17.1637 17.6484 16.6595 17.6484H5.65951ZM11.1595 14.1484L6.57617 9.77344L7.8595 8.50469L10.2428 10.7797V3.64844H12.0762V10.7797L14.4595 8.50469L15.7428 9.77344L11.1595 14.1484Z"
              fill="white"
            /></svg
        ></mat-icon>

        Export to excel
      </button>
    </div>
    <!-- </form> -->
  </div>
  <div class="content-2">
    <span>Selected Phase:  </span> <span>{{phaseSelected?.name}}</span>
  </div>
</div>

<!-- start change about reisk-management btn-export-to-excel -->

<div mat-dialog-container class="phase-box">
  <div class="form-dialog-header">
    <div class="form-dialog-header__text-box">
      <p class="form-dialog-header__text-box__text">
        {{ phaseId ? "Edit" : "Add" }} Phase
      </p>
    </div>

    <div class="form-dialog-header__icon-box">
      <button
        class="form-dialog-header__icon-box__btn"
        (click)="onCloseDialog()"
      >
        <mat-icon class="form-dialog-header__icon-box__icon-size">
          <img
            class="form-dialog-header__icon-box__icon-size__icon"
            src="../../../assets/shared-image/Vector-close.svg"
            alt="~/Frontend/images/icon _ Vector-close"
          />
        </mat-icon>
      </button>
    </div>
  </div>

  <!-- <h1 mat-dialog-title>{{ phaseId ? "Edit" : "Add" }} Phase</h1> -->
  <div class="form-dialog-conten-box-1">
    <mat-dialog-content>
      <form [formGroup]="phaseForm" class="form-dialog-content">
        <div class="form-dialog-content__field--grid-column">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >Name</mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <input matInput formControlName="name" />
          </mat-form-field>
        </div>

        <div class="form-dialog-content__field--grid-column">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >Reporting year</mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <input matInput formControlName="reporting_year" type="number"/>
          </mat-form-field>
        </div>

        <div class="form-dialog-conten-box-2">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >Start date</mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field--2"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <input
              matInput
              [matDatepicker]="start_datePicker"
              formControlName="start_date"
            />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="start_datePicker">
              <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #start_datePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="form-dialog-conten-box-2">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >End date</mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field--2"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <input
              matInput
              [matDatepicker]="end_datePicker"
              formControlName="end_date"
            />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="end_datePicker">
              <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #end_datePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="form-dialog-conten-box-2">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >Previous Phase<span class="star">*</span></mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field--2"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <mat-select formControlName="previous_phase">
              <mat-option *ngFor="let phase of phases" [value]="phase.id">
                {{ phase.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <section class="example-section" >
          <mat-slide-toggle
            formControlName="show_in_home"
            class="example-margin"
            [disabled]="phase?.active"
            matTooltip="Can not hide active phase"
            matTooltipPosition="above"
            [matTooltipDisabled]="!phase?.active"
          >
            Show in home page
          </mat-slide-toggle>
        </section>

        <!-- <div class="form-dialog-conten-box-2">
          <div class="form-dialog-content__title-box">
            <mat-label class="form-dialog-content__title-box__title"
              >Status<span class="star">*</span></mat-label
            >
          </div>
          <mat-form-field
            class="form-dialog-content__field--2"
            floatLabel="always"
            hideRequiredMarker
            appearance="outline"
          >
            <mat-select formControlName="status">
              <mat-option value="Open">Open</mat-option>
              <mat-option value="Closed">Closed</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->

        <!-- //Update Button----- -->
        <div class="form-dialog-conten-button-box-3">
          <mat-dialog-actions align="end">
            <!-- <button mat-button mat-dialog-close>Cancel</button> -->
            <div class="form-dialog-conten__button-box">
              <button
                class="form-dialog-conten__button-box__btn"
                mat-button
                color="primary"
                (click)="submit()"
              >
                {{ phaseId ? "Save" : "Add" }}
              </button>
            </div>
          </mat-dialog-actions>
        </div>
      </form>
    </mat-dialog-content>
  </div>
</div>

<div class="team-members-container">
    <nav class="risk-nav-main">
      <ul class="risk-nav-list">
        <li>
          <a class="risk-nav-link" routerLink="/archive">Archive</a>
        </li>
        <li>
          <a routerLink="''" class="risk-nav-link">></a>
        </li>
        <li>
          <a
            class="risk-nav-link"
            >{{
              officalCode
            }}</a
          >
        </li>
        <li>
          <a routerLink="''" class="risk-nav-link">></a>
        </li>
        <li>
          <a
            class="risk-nav-link"
            >Team members</a
          >
        </li>
      </ul>
    </nav>
    <div class="team-member-content-box">
      <!-- step-1 -->
      <div class="team-members-text-box">
        <p class="team-members-text-box__text">Team members</p>
      </div>
  
  
    
  
  
      <div class="table-box">
        <div class="table-box">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
            <ng-container matColumnDef="User Name">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>
                <span class="th-3">User name</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <span class="td-3-h">{{ element["User Name"] }}</span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="Email">
              <th mat-header-cell *matHeaderCellDef>
                <span class="h3">Email</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <span class="td-h"
                  >{{ element?.user?.email || element.email }}
                </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="User">
              <th mat-header-cell *matHeaderCellDef>
                <span class="h4"> User name </span>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.user?.full_name }}
              </td>
            </ng-container>
  
            <!-- Role Column -->
            <ng-container matColumnDef="Role">
              <th mat-header-cell *matHeaderCellDef>
                <span class="h4">Role</span>
              </th>
              <td mat-cell *matCellDef="let element">{{ element.role }}</td>
            </ng-container>
  
            <!-- Creation Date Column -->
            <ng-container matColumnDef="Creation Date">
              <th mat-header-cell *matHeaderCellDef>
                <span class="h4">Creation Date</span>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdAt | date : "MMMM d, y" }}
              </td>
            </ng-container>
  
            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef>
                <span class="h4">Status</span>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.user_id ? "Active" : "Invited" }}
              </td>
            </ng-container>
  
            <tr
              class="table-header"
              mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
              class="table-header"
              mat-row
              *matRowDef="let row; columns: displayedColumns"
            ></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  